nav {
  &.navbar {
    background: #084d6d !important;
  }
  .navbar-brand {
    small {
      background: #ffffff;
      border-radius: 2px;
      color: #000000;
      font-size: 10px;
      padding: 2px;
    }
  }
}

.modal-body .code-snippet {
  max-height: 300px;
  overflow: auto;
}

code.text-white.small {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickCopy {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 220px;
  left: 100px;
  top: 60px;
  background: #c2c2c299;
  border-radius: 10px;
}